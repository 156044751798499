//const API_URL = 'http://10.12.0.49:8000/api/'
const API_URL = 'https://api.raro-srv.com/api/'
const SECRET_KEY = 'rarosecretkey#@$%!';
import CryptoJS from 'crypto-js'; 

export function encryptData(data) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  return encryptedData;
}

export function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}


export default API_URL